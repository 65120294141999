@tailwind base;
@tailwind components;
@tailwind utilities;

@import "components/type-switch";
@import "components/elevator-anim";
@import "components/scrubber";
@import "components/input";

:root {
  --max-width: 380px;
}

body {
  @apply font-roboto;
}

html {
  @apply h-full  overflow-y-auto;
}

.bg-front {
  //@apply bg-brand-blue-300;
  //@apply text-brand-grey-700;
  @apply bg-brand-theme-400;
  @apply text-brand-theme-800;
}

.text-bg-front {
  //@apply text-brand-blue-300;
  @apply text-brand-theme-200;
}

.padding-front {
  @apply px-16  py-10;
}

.button {
  @apply bg-brand-theme-800  rounded-xl  py-3  px-5;
  @apply text-brand-theme-200  text-center;

  &.is-secondary {
    @apply bg-brand-grey-400;
  }

  &.is-inverted {
    @apply bg-brand-theme-200  text-brand-theme-800;
  }

  &.is-brand {
    @apply bg-brand-theme-500  text-brand-theme-200;
  }
}

.\-shadow-2xl {
  box-shadow: 0px -12px 22px 0px #00000040;
}

.scroll-wrapper {
  @apply relative  h-full  overflow-y-auto;
}

.content-wrapper {
  max-width: var(--max-width);
  margin: 0 auto;
}

.content-max-width {
  max-width: var(--max-width);
}

.backdrop-blur {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.type-switch-icon {
  &:not(.is-active) {
    path {
      opacity: 0.3 !important;
    }
  }
}