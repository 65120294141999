.elevator-anim {
  @apply relative;

  .doors-container {
    @apply absolute  overflow-hidden;
    top: 80px;
    bottom: 6px;
    left: 6px;
    right: 6px;
  }

  .doors {
    @apply absolute inset-0  flex  flex-row  items-center  justify-between;
    @apply border-b-4  border-brand-grey-300;
    @apply overflow-hidden;
  }

  .door {
    @apply h-full  bg-brand-grey-700  transition-all  duration-500;
    @apply bg-brand-grey-300;

    width: calc(100% / 2 - 1px);

    &-left {
      @apply rounded-tl-lg;
    }
    &-right {
      @apply rounded-tr-lg;
    }
  }
}