.type-switch {
  @apply text-bg-front  flex  flex-row  items-center  space-x-3;

  &-icon {
    @apply block  w-12  h-12  rounded-lg  p-2;
    @apply bg-brand-theme-200  text-brand-grey-400;

    &.is-active {
      @apply bg-brand-theme-800  text-brand-theme-200;
    }
  }
}