.scrubber {
  .bar {
    @apply bg-brand-theme-200;
    // @apply bg-brand-blue-300;
  }

  .bar__progress,
  .bar__thumb {
    @apply bg-brand-theme-500;
  }

  .bar__thumb {
    width: 18px;
    height: 18px;
    @apply bg-brand-theme-500;
  }

  &.hover {
    .bar__thumb {
      width: 24px;
      height: 24px;
    }
  }
}